import React, { useEffect, useState, useContext } from 'react';
import { navigate } from 'gatsby';
import { Skeleton } from '@mantine/core';
import moment from 'moment';
import  Image  from '../../assets/images/favicons/luth-logo-51.png';
import  logos  from '../../assets/images/luth-logo.png';

import '../../assets/css/print.css';

const BioDataPrint = (props) => {
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState();

  useEffect(() => {
    const local = localStorage.getItem('local');
    const parsed = JSON.parse(local);
    if (parsed.courseForm) {
      setDetails(parsed.courseForm);
      setTimeout(() => {
        setLoading(false);
        window.print();
      }, 3000);
    } else {
      console.log('no parsed');
      navigate('/profile');
    }
  }, []);

  const styleO = {
    bd: {
      margin: 0,
      padding: 0,
      fontFamily: 'Lato',
      fontWeight: 300,
      fontSize: '16px',
      background: 'rgb(241, 241, 241)',
      boxSizing: 'border-box',
    },
    page: {
      position: 'relative',
      width: '234mm',
      height: '297mm',
      display: 'block',
      background: 'white',
      pageBreakAfter: 'auto',
      margin: '50px',
      overflow: 'hidden',
      backgroundImage: `url(${Image})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },

    pageFirst: {
      borderLeft: '5px solid green',
    },

    bottom: {
      position: 'absolute',
      left: '11mm',
      right: '10mm',
      top: '5mm',
    },
    groupAside: {
      marginTop: '3mm',
      float: 'right',
      margin: '0 1.5%',
      width: '54%',
    },

    group: {
      marginTop: '3mm',
    },

    line: {
      color: 'black',
      position: 'relative',
    },

    center: {
      textAlign: 'center',
    },

    first: {
      top: '3mm',
    },

    logo: {
      position: 'absolute',
      width: '180px',
      height: '180px',
      top: '20mm',
      left: '175mm',
    },

    section: {
      float: 'left',
      margin: '0 1.5%',
      width: '40%',
    },
    aside: {
      float: 'right',
      margin: '0 1.5%',
      width: '54%',
    },
    headText: {
      fontWeight: 'bold',
    },
    inner: {
      marginBottom: '10px',
    },

    innerx2: {
      marginBottom: '10px',
    },

    req: {
      top: '50px !important',
    },
    passport: {
      top: '22mm',
      left: '174mm',
      width: '44px',
      height: '577px',
      position: 'absolute',
    },
    titleHead: {
      color: 'black !important',
      fontWeight: '700',
      textAlign: 'center',
    },
    subTitle: {
      fontWeight: '700',
      textAlign: 'center',
      marginBottom: '10px',
      color: 'black',
      position: 'relative',
    },
    bioTitle: {
      fontWeight: '700',
      textAlign: 'center',
      fontSize: '14px',
      color: 'black',
      position: 'relative',
    },
    lineInner: {
      color: 'black',
      position: 'relative',
      fontSize: '15px',
      fontWeight: '700',
    },
    per: {
      fontWeight: '700',
      textAlign: 'center',
      marginBottom: '10px',
      color: 'black',
      position: 'relative',
    },
    groupSection: {
      marginTop: '3mm',
      float: 'left',
      margin: '0 1.5%',
      width: '40%',
    },
    lineFirstInner: {
      color: 'black',
      position: 'relative',
      top: '3mm',
      fontSize: '15px',
      fontWeight: '700',
    },
    reqLineInnerCenter: {
      color: 'black',
      position: 'absolute',
      textAlign: 'center',
      top: '131mm',
      left: '46mm',
      fontWeight: '700',
    },
    reqLineInner: {
      color: 'black',
      position: 'absolute',
      top: '150mm',
      right: '146mm',
    },
    normalText: {
      fontSize: '16px',
      fontWeight: '100',
    },
  };
  return (
    <Skeleton visible={loading}>
      {details && (
        <div style={styleO.bd}>
          <div style={styleO.page}>
            <div style={styleO.bottom}>
            <div style={styleO.titleHead}>
              <img src={logos} />
              </div>
              <div style={styleO.titleHead}>
                COMMUNITY HEALTH OFFICERS TRAINING SCHOOL
              </div>
              <div style={styleO.subTitle}>
                LAGOS UNIVERISITY TEACHING HOSPITAL, (LUTH) IDI-ARABA, LAGOS
              </div>
              <div style={styleO.bioTitle}>APPLICANT BIO-DATA</div>
              <div style={styleO.group}>
                <div style={styleO.lineInner}>
                  <span>Application ID:</span>{' '}
                  <span style={styleO.normalText}> {details.appNo}</span>
                </div>
                <div style={styleO.subTitle}>APPLICANT PERSONAL DETAILS</div>
              </div>
              <div style={styleO.groupSection}>
                <div style={styleO.lineInner}>
                  <strong>Surname:</strong>
                  <span style={styleO.normalText}>
                    {' '}
                    {details.lastName.toUpperCase()}
                  </span>
                </div>
                <div style={styleO.lineInner}>
                  <strong>Fisrt Name:</strong>
                  <span style={styleO.normalText}>
                    {' '}
                    {details.firstName.toUpperCase()}
                  </span>
                </div>
                <div style={styleO.lineInner}>
                  <strong>Sex:</strong>
                  <span style={styleO.normalText}> {details.gender}</span>
                </div>
                <div style={styleO.lineInner}>
                  <strong>Phone Number:</strong>
                  <span style={styleO.normalText}> {details.phone}</span>
                </div>
                <div style={styleO.lineInner}>
                  <strong>Address:</strong>
                  <span style={styleO.normalText}> {details.address}</span>
                </div>
              </div>
              <div style={styleO.groupAside}>
                <div style={styleO.lineFirstInner}>
                  <strong>Middle Name:</strong>
                  <span style={styleO.normalText}>
                    {' '}
                    {details.middleName && details.middleName.toUpperCase()}
                  </span>
                </div>
                <div style={styleO.lineFirstInner}>
                  <strong>Date of Birth:</strong>
                  <span style={styleO.normalText}>
                    {' '}
                    {moment(details.dob, 'YYYY-MM-DD').format('Do MMM YYYY')}
                  </span>
                </div>
                <div style={styleO.lineFirstInner}>
                  <strong>State of Origin:</strong>
                  <span style={styleO.normalText}> {details.state}</span>
                </div>
                <div style={styleO.lineFirstInner}>
                  <strong>Email Address:</strong>
                  <span style={styleO.normalText}> {details.email}</span>
                </div>
                <div style={styleO.lineFirstInner}>
                  <strong>Marital Status:</strong>
                  <span style={styleO.normalText}>
                    {' '}
                    {details.maritalStatus}
                  </span>
                </div>
              </div>
              <div style={styleO.reqLineInnerCenter}>
                KINDLY COME WITH THE FOLLOWING TO THE EXAMINATION HALL
              </div>
              <div style={styleO.groupSection}>
                <div
                  style={{
                    color: 'black',
                    position: 'absolute',
                    top: '193mm',
                    left: '3mm',
                  }}
                >
                  <strong>1.</strong> Original SSCE/GCE/NECO Certificate
                </div>
                <div
                  style={{
                    color: 'black',
                    position: 'absolute',
                    top: '200mm',
                    left: '3mm',
                  }}
                >
                  <strong>2.</strong> Practicing License
                </div>
                <div
                  style={{
                    color: 'black',
                    position: 'absolute',
                    top: '207mm',
                    left: '3mm',
                  }}
                >
                  <strong>3.</strong> Proof of Remitta Payment
                </div>
              </div>
              <div>
                <img
                  style={styleO.logo}
                  src={details.passport}
                  alt='passport'
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Skeleton>
  );
};

export default BioDataPrint;
